// Generated by script, don't edit it please.
import createSvgIcon from '../createSvgIcon';
import ArrowUpSvg from '../icons/direction/ArrowUp';
var ArrowUp = createSvgIcon({
    as: ArrowUpSvg,
    ariaLabel: 'arrow up',
    category: 'direction',
    displayName: 'ArrowUp'
});
export default ArrowUp;
