// Generated by script, don't edit it please.
import createSvgIcon from '../createSvgIcon';
import CalendarSvg from '../icons/time/Calendar';
var Calendar = createSvgIcon({
    as: CalendarSvg,
    ariaLabel: 'calendar',
    category: 'time',
    displayName: 'Calendar'
});
export default Calendar;
