// Generated by script, don't edit it please.
import createSvgIcon from '../createSvgIcon';
import PagePreviousSvg from '../icons/direction/PagePrevious';
var PagePrevious = createSvgIcon({
    as: PagePreviousSvg,
    ariaLabel: 'page previous',
    category: 'direction',
    displayName: 'PagePrevious'
});
export default PagePrevious;
