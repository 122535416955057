// Generated by script, don't edit it please.
import createSvgIcon from '../createSvgIcon';
import TimeSvg from '../icons/time/Time';
var Time = createSvgIcon({
    as: TimeSvg,
    ariaLabel: 'time',
    category: 'time',
    displayName: 'Time'
});
export default Time;
