// Generated by script, don't edit it please.
import createSvgIcon from '../createSvgIcon';
import PageNextSvg from '../icons/direction/PageNext';
var PageNext = createSvgIcon({
    as: PageNextSvg,
    ariaLabel: 'page next',
    category: 'direction',
    displayName: 'PageNext'
});
export default PageNext;
